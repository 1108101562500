<template>
    <div>
        <a class="section-profile" @click="ctaCart()">
            <img v-img-prefix :src="`/public-assets/icon-cart-u`" alt="cart" class="section-profile-icon">
            ({{ cartSize }})
        </a>
        <CartBar ref="cartBar"/>
    </div>
</template>

<script>
import CartBar from "@/components/cart/CartBar.vue";
import cart from "@/components/mixins/cart";

export default {
    mixins: [cart],
    components: {CartBar},
    mounted() {
        this.updateInfo();
    },
    methods: {
        ctaCart() {
            if (window.location.pathname !== '/generar-pedido') {
                this.$refs.cartBar.show()
            }
        },
    }
}
</script>