
<div v-if="cartOpen">
    <div id="sidebar-container" :class="{'showNav': cartOpen}" class="sidebar-container" v-click-outside="hide">
        <div class="sidebar-header cart-bar-header">
            <div class="cart-bar-title">
                <span>Mi carrito</span>
                <span><i class="fa-solid fa-x" @click="hide()"></i></span>
            </div>
        </div>
        <div class="sidebar-body cart-bar-body">
            <template v-if="products.length > 0">
                <ProductBar v-for="product in products" :product="product"
                            @removedProduct="removeItemFromList($event)"></ProductBar>
            </template>
            <template v-else>
                <div class="cart-bar-no-products">
                    <p class="cart-bar-no-products-title">Tu carrito se encuentra vacio</p>
                    <p class="cart-bar-no-products-text">Elige de nuestro catálogo las piezas que te gusten y
                        agrégalas a tu carrito de compra</p>
                </div>
            </template>
        </div>
        <div v-if="products_count > 0" class="sidebar-footer cart-bar-footer">
            <div class="cart-bar-subtotal">
                <span>Subtotal</span>
                <span>${{ formatPrice(subtotal()) }}</span>
            </div>
            <button class="btn btn-primary btn-blue w-100 mt-3 mb-0" @click="redirectToGenerateOrder()">Pagar</button>
            <button class="btn btn-primary btn-orange w-100 mb-0" @click="redirectToCatalog()">Seguir comprando</button>
        </div>
    </div>
    <div id="sidebar-background" class="sidebar-backdrop bg-dark"></div>
</div>
