<template>
    <div v-if="cartOpen">
        <div id="sidebar-container" :class="{'showNav': cartOpen}" class="sidebar-container" v-click-outside="hide">
            <div class="sidebar-header cart-bar-header">
                <div class="cart-bar-title">
                    <span>Mi carrito</span>
                    <span><i class="fa-solid fa-x" @click="hide()"></i></span>
                </div>
            </div>
            <div class="sidebar-body cart-bar-body">
                <template v-if="products.length > 0">
                    <ProductBar v-for="product in products" :product="product"
                                @removedProduct="removeItemFromList($event)"></ProductBar>
                </template>
                <template v-else>
                    <div class="cart-bar-no-products">
                        <p class="cart-bar-no-products-title">Tu carrito se encuentra vacio</p>
                        <p class="cart-bar-no-products-text">Elige de nuestro catálogo las piezas que te gusten y
                            agrégalas a tu carrito de compra</p>
                    </div>
                </template>
            </div>
            <div v-if="products_count > 0" class="sidebar-footer cart-bar-footer">
                <div class="cart-bar-subtotal">
                    <span>Subtotal</span>
                    <span>${{ formatPrice(subtotal()) }}</span>
                </div>
                <button class="btn btn-primary btn-blue w-100 mt-3 mb-0" @click="redirectToGenerateOrder()">Pagar</button>
                <button class="btn btn-primary btn-orange w-100 mb-0" @click="redirectToCatalog()">Seguir comprando</button>
            </div>
        </div>
        <div id="sidebar-background" class="sidebar-backdrop bg-dark"></div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import ProductBar from "@/components/cart/ProductBar.vue";
import ProductPrice from "@/components/mixins/ProductPrice";
import cart from "@/components/mixins/cart";

export default {
    components: {ProductBar},
    mixins: [cart, ProductPrice],
    data() {
        return {
            catalogId: '9884', // TODO cambiar a slug de catalogo centralizado
        }
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    methods: {
        hide() {
            const container = document.getElementById('sidebar-container');
            if (container) container.classList.add('hideNav');

            setTimeout(() => {
                const sidebarBg = document.getElementById('sidebar-background');
                if (sidebarBg) sidebarBg.classList.add('d-none');
            }, 250)

            setTimeout(() => {
                this.setCartOpen(false)
            }, 500)
        },
        show() {
            this.setCartOpen(true)
        },
        subtotal() {
            if (this.products == null) return 0
            let subtotal = 0
            for (const product of this.products) {
                subtotal += product.on_sale_price
            }
            return subtotal
        },
        redirectToCatalog() {
            window.location.href = `/catalogo/${this.catalogId}`
        },
        redirectToGenerateOrder() {
            window.location.href = '/generar-pedido'
        },
    }
}
</script>

<style lang="scss" scoped>
.sidebar-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    // dejar a 0
    top: 65px;
    //
    left: auto;
    right: 0;
    width: 500px;
    max-width: 100%;
    height: 100vh;
    max-height: 100%;
    margin: 0;
    outline: 0;
    transform: translateX(0);
    background-color: white;
}

.sidebar-backdrop {
    position: fixed;
    // dejar a 0
    top: 65px;
    //
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    opacity: 0.6;
}

.sidebar-header {
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    align-items: center;
}

.sidebar-body {
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
}

.sidebar-footer {
    flex-grow: 0;
}

@keyframes showNav {
    from {
        right: -60%;
    }
    to {
        right: 0;
    }
}

@-webkit-keyframes showNav {
    from {
        right: -60%;
    }
    to {
        right: 0;
    }
}

@-moz-keyframes showNav {
    from {
        right: -60%;
    }
    to {
        right: 0;
    }
}

@-o-keyframes showNav {
    from {
        right: -60%;
    }
    to {
        right: 0;
    }
}

.showNav {
    -webkit-animation: showNav .5s ease forwards;
    -moz-animation: showNav .5s ease forwards;
    -o-animation: showNav .5s ease forwards;
    animation: showNav .5s ease forwards;
}

@keyframes hideNav {
    from {
        right: 0;
    }
    to {
        right: -60%;
    }
}

@-webkit-keyframes hideNav {
    from {
        right: 0;
    }
    to {
        right: -60%;
    }
}

@-moz-keyframes hideNav {
    from {
        right: 0;
    }
    to {
        right: -60%;
    }
}

@-o-keyframes hideNav {
    from {
        right: 0;
    }
    to {
        right: -60%;
    }
}

.hideNav {
    -webkit-animation: hideNav .5s ease forwards;
    -moz-animation: hideNav .5s ease forwards;
    -o-animation: hideNav .5s ease forwards;
    animation: hideNav .5s ease forwards;
}

.cart-bar-body {
    background-color: white;
    padding: 1rem 1.5rem;
}

.cart-bar-footer {
    background-color: #f5f5f5;
    height: 52.5%;
    padding: 1rem;
}

.cart-bar-header {
    background-color: #294796;
    color: white;
    width: 100%;
}

.cart-bar-subtotal {
    display: flex;
    justify-content: space-between;
    color: #294796;
    font-weight: bolder;
}

.cart-bar-title {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    width: 100%;

    i {
        cursor: pointer;
    }
}

.cart-bar-no-products {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.cart-bar-no-products-title {
    font-size: 18px;
    font-weight: bolder;
    color: #ff9114;
    width: 80%;
}

.cart-bar-no-products-text {
    color: #797979;
    width: 80%;
}
</style>